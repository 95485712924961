<template>
  <div class="flex justify-center items-center mt-10">
    <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
      <div class="text-center my-4">
        <h2 class="text-lg font-bold">
          Crear Sancion
        </h2>
      </div>
      <div class="flex flex-wrap content-around">
        <div class="w-full flex flex-col items-center py-5 px-2 ">
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm">Incidentes</label>
              <Dropdown
                v-model="model.base"
                :options="incidentes"
                class="w-full"
                optionValue="id"
                optionLabel="observacion"
                placeholder="Seleccione">
                  <template #value="slotProps">
                    <div class="country-item country-item-value" v-if="slotProps.value">
                      <div v-if="incidentes.find(a => a.id === slotProps.value)">
                        <div>{{ incidentes.find(a => a.id === slotProps.value).observacion }}</div>
                      </div>
                    </div>
                    <span v-else>
                      {{slotProps.placeholder}}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex">
                      <div class="w-2/12">
                        <p style="color: #7F7E81" class="text-xs">
                          ID:
                        </p>
                        <p class="text-normal">
                          {{ slotProps.option.id }}
                        </p>
                      </div>
                      <div class="w-5/12">
                        <p style="color: #7F7E81" class="text-xs">
                          Observaciones:
                        </p>
                        <p class="text-normal">
                          {{ slotProps.option.observacion.slice(0, 40) }}...
                        </p>
                      </div>
                    </div>
                  </template>
              </Dropdown>
              <MessageError :text="errors.base"/>
            </div>
            <div class="mb-4">
              <label class="font-medium text-sm">Observaciones</label>
              <Textarea
                rows="3" cols="30"
                class="w-full uppercase"
                inputClass="w-full"
                placeholder="Escribir..."
                v-model="model.observacion"
                />
              <MessageError :text="errors.observacion"/>
            </div>
            <div class="w-full">
              <div class="mb-4">
                <label class="font-medium text-sm">Fecha de notificacion</label>
                <input class="p-inputtext w-full"
                      type="datetime-local"
                      v-model="model.fechaNotificacion"
                >
                <MessageError :text="errors.fechaNotificacion"/>
              </div>
            </div>
            <div class="px-2 w-full">
              <div style="max-height: 150px; overflow-y: auto;">
                <div class="flex justify-between" v-for="(item, key) in adjuntos" :key="key">
                  <div class="flex mt-2">
                    <i class="pi pi-file-pdf mr-2 text-black"></i>
                    <p>{{ item.fileName }}</p>
                  </div>
                  <div>
                    <button><i class="pi pi-trash mr-2 text-red-600"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class=" mt-4 w-full mb-4 flex flex-col">
              <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                Agregar nuevo soporte
              </button>
              <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                <div>
                  <div>
                    <label class="text-sm" for="titulo">Titulo</label>
                    <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                    class="text-sm mt-2 uppercase block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  </div>
                  <div class="my-2">
                    <label class="text-sm" for="documento">Adjunte el documento</label>
                    <div class="mt-2">
                      <button class="w-full">
                        <FilePond ref="pond"/>
                      </button>
                    </div>
                  </div>
                </div>
                <template class="text-center" #footer>
                    <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                </template>
            </Dialog>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-between px-2 mb-9">
          <Button label="Cancelar"
                  class="bg-blue-900 p-button-outlined"
                  style="width: 244px;"
                  @click="$router.go(-1)"
          />
          <Button label="Crear"
                  class="bg-blue-900"
                  style="width: 244px;"
                  type="button"
                  @click="handleNext"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storeIncidentes from '../../../../store/incidencia.store'
import storeDesacatos from '../../../../store/desacatos.store'
import fallosStore from '../../../../store/fallos.store'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import FilePondStore from '../../../../store/filepond.store'
import { onMounted, ref, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'crearDesacatos',
  components: {
    FilePond
  },
  setup () {
    const pond = ref()
    const url = ref(`${process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS}/desacatos`)
    const router = useRouter()
    const route = useRoute()
    const dataDropdown = ref([])
    const adjuntos = ref([])
    const displayMaximizable = ref(false)
    const title = ref('')
    const toast = useToast()
    const idFallo = ref()

    /** computed **/
    const incidentes = computed(
      () => storeIncidentes.getters.incidencias.rows
    )
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    /** state **/
    const schema = yup.object({
      tipo: yup.number(),
      base: yup.number().nullable().required('El campo es requerido').label(''),
      observacion: yup.string().nullable().required('El campo es requerido').label(''),
      fechaNotificacion: yup.date().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('tipo', null, {
      initialValue: 2
    })
    useField('base')
    useField('observacion')
    useField('fechaNotificacion')

    /** methodos **/
    const handleNext = handleSubmit(async (values) => {
      if (!values) return console.error('Error => validation')
      values.paths = adjuntos.value
      storeDesacatos.dispatch('createDesacato', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El documento ya existe', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Sanción registrada con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
        }
      })
    })

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
      })
    }

    /** hooks **/
    onMounted(() => {
      storeDesacatos.dispatch('getPaginate')
      fallosStore.dispatch('getPaginate').then(() => {
        idFallo.value = incidentes.value.filter(a => a.base === parseInt(route.query.idTutela))
      })
      storeIncidentes.dispatch('getIncidentesTutelas', parseInt(route.query.idTutela))
    })

    return {
      errors,
      model,
      handleNext,
      dataDropdown,
      pond,
      url,
      incidentes,
      displayMaximizable,
      closeMaximizable,
      openMaximizable,
      title,
      adjuntos,
      idFallo
    }
  }
}
</script>

<style lang="scss">
.p-message .p-message-wrapper{
  padding: 0.5rem 1.5rem;
}
</style>
